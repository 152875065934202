// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, IconButton } from '@mui/material';
import alxwhitelogo from '../../../assets/AlphaXWhiteLogo.svg';
import { grey } from '@mui/material/colors';
import './typography.css';
import { Link } from 'react-router-dom';
import TelegramIcon from '@mui/icons-material/Telegram';
import { FaXTwitter, FaDiscord, FaGithub } from 'react-icons/fa6';
import { SiGitbook } from 'react-icons/si';
import GradientCircle from './GradientCircle';

const iconData = [
    { component: <TelegramIcon sx={{ color: '#FFFFFF' }} />, link: 'https://t.me/alphadao_token' },
    { component: <FaDiscord style={{ color: '#FFFFFF' }} />, link: 'https://discord.gg/your_discord_link' },
    { component: <SiGitbook style={{ color: '#FFFFFF' }} />, link: 'https://your_gitbook_link' },
    { component: <FaGithub style={{ color: '#FFFFFF' }} />, link: 'https://github.com/your_github_username/your_repo' },
    { component: <FaXTwitter style={{ color: '#FFFFFF' }} />, link: 'https://twitter.com/alphadaotoken' }
];

const buttonStyles = {
    color: grey[400],
    textDecoration: 'none',
    py: 0.6,
    ':hover': { textDecoration: 'underline' }
};

const buttonRoutes = [
    { name: 'Community', path: 'https://t.me/alphadaotoken' },
    { name: 'Press room', path: '/pressroom' },
    { name: 'FAQs', path: '/faqs' }
];

const buttonRoutes2 = [
    { name: 'FAQs', path: '/faqs' },
    { name: 'Press room', path: '/pressroom' },
    { name: 'Whitepaper', path: '/whitepaper' }
];

const buttonRoutes3 = [
    { name: 'Lend and borrow', path: '/lendborrow' },
    { name: 'Lease platform', path: '/lease' },
    { name: 'Auctions', path: '/auctions' }
];

const SubFooter = () => {
    const theme = useTheme();
    const handleButtonClick = (link) => {
        window.open(link, '_blank'); // Open link in a new tab
    };

    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    px: 2,
                    alignItems: 'center',
                    background: '#100e11'
                }}
            >
                <Grid item lg={6} md={6} sm={10} xs={12} display="flex" justifyContent="center" zIndex={995}>
                    <Grid container lg={6} xs={10}>
                        <Typography
                            textAlign="left"
                            sx={{
                                backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                                fontSize: 32,
                                backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                                backgroundSize: '100%',
                                backgroundRepeat: 'repeat',
                                fontWeight: 600,
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            Experience
                        </Typography>
                        <Typography fontSize={32} ml={1} fontWeight={600} sx={{ color: '#FFFFFF' }}>
                            true
                        </Typography>
                        <Typography fontSize={32} fontWeight={600} sx={{ color: '#FFFFFF' }}>
                            decentralized finance
                        </Typography>
                        <Typography fontSize={18} sx={{ color: grey[500], mt: 1, mb: { lg: 0, md: 0, sm: 10, xs: 10 } }}>
                            AlphaDAO supports and empowers true decentralization throughout the blockchain. AlphaDAO is fully-governed by
                            its users.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container lg={6} md={6} sm={10} flexDirection="row" justifyContent="space-between" display="flex" p={2} zIndex={995}>
                    <Grid container lg={4} sm={5} xs={5} flexDirection="column" display="flex">
                        <Typography textAlign="left" sx={{ color: grey[50], fontSize: 26, fontWeight: 600 }}>
                            AlphaDAO
                        </Typography>
                        {buttonRoutes.map(({ name, path }, index) => (
                            <Typography key={index} fontSize={16} component={Link} to={path} sx={buttonStyles}>
                                {name}
                            </Typography>
                        ))}
                    </Grid>
                    <Grid container lg={4} sm={5} xs={5} flexDirection="column" display="flex">
                        <Typography textAlign="left" sx={{ color: grey[50], fontSize: 26, fontWeight: 600 }}>
                            Resources
                        </Typography>
                        {buttonRoutes2.map(({ name, path, external }, index) => (
                            <Typography
                                href={external ? path : undefined}
                                key={index}
                                fontSize={16}
                                component={external ? 'a' : Link}
                                to={external ? undefined : path}
                                sx={buttonStyles}
                            >
                                {name}
                            </Typography>
                        ))}
                    </Grid>
                    <Grid container lg={4} sm={5} xs={5} flexDirection="column" display="flex" sx={{ mt: { lg: 0, md: 5, sm: 5, xs: 5 } }}>
                        <Typography textAlign="left" sx={{ color: grey[50], fontSize: 26, fontWeight: 600 }}>
                            Products
                        </Typography>
                        {buttonRoutes3.map(({ name, path }, index) => (
                            <Typography key={index} component={Link} to={path} fontSize={16} sx={buttonStyles}>
                                {name}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
                <Grid
                    container
                    lg={12}
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    mt={5}
                    mb={-25}
                    pb={5}
                    zIndex={995}
                >
                    <Grid container lg={3} md={2.8} sm={4} display="flex" justifyContent="center" alignItems="center">
                        <img src={alxwhitelogo} alt="whitelogo" width={35} height={35} />
                        <Typography variant="m1" sx={{ color: '#FFFFFF', pl: 1, mt: 1 }}>
                            AlphaDAO
                        </Typography>
                    </Grid>
                    <Grid container lg={3.5} md={4.7} sm={7} display="flex" justifyContent="center" my={1}>
                        <Typography textAlign="center" sx={{ color: grey[300] }}>
                            Copyright © 2024 AlphaDAO. All rights reserved.
                        </Typography>
                    </Grid>
                    <Grid container lg={3} md={4} display="flex" justifyContent="center" my={1}>
                        {iconData.map((icon, index) => (
                            <IconButton
                                key={index}
                                sx={{ borderRadius: 1, bgcolor: '#28242b', mx: 1 }}
                                onClick={() => handleButtonClick(icon.link)}
                            >
                                {icon.component}
                            </IconButton>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ position: 'relative', height: 0, justifyContent: 'right', bottom: 250, right: 0 }}>
                <GradientCircle size={300} color="#AF4A38" />
            </Grid>
        </>
    );
};

export default SubFooter;
