import { Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import alxlogo from '../../assets/alxlogo.svg';
import alxwhitelogo from '../../assets/AlphaXWhiteLogo.svg';
import alxblacklogo from '../../assets/AlxBlackLogo.svg';

const PressRoom = () => {
    const theme = useTheme();
    return (
        <Grid container sx={{ justifyContent: 'left', p: 2, display: 'flex', my: 10 }}>
            {/* Introduction */}
            <Grid container xs={12} sm={12} md={6} lg={12} mt={10}>
                <Typography
                    textAlign="left"
                    sx={{
                        backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                        fontSize: { lg: 50, md: 40, sm: 30, xs: 30 },
                        letterSpacing: '1px',
                        backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                        backgroundSize: '100%',
                        backgroundRepeat: 'repeat',
                        fontWeight: 505,
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        pl: { xs: 0, sm: 0, md: 7.5, lg: 10 }
                    }}
                >
                    Press
                </Typography>
                <Typography
                    sx={{
                        color: grey[200],
                        fontSize: { lg: 50, md: 40, sm: 30, xs: 30 },
                        fontWeight: 505,
                        pl: 1
                    }}
                >
                    room
                </Typography>
            </Grid>
            <Grid container lg={6} alignItems="center">
                <Typography
                    sx={{
                        color: grey[200],
                        fontSize: { xs: 28, sm: 28, md: 36, lg: 40 },
                        fontWeight: 505,
                        pt: 4,
                        pl: { xs: 0, sm: 0, md: 7.5, lg: 10 }
                    }}
                >
                    About the
                </Typography>
                <Typography
                    textAlign="left"
                    sx={{
                        backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                        fontSize: { xs: 28, sm: 28, md: 36, lg: 40 },
                        letterSpacing: '1px',
                        backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                        backgroundSize: '100%',
                        backgroundRepeat: 'repeat',
                        fontWeight: 505,
                        backgroundClip: 'text',
                        pt: 4,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        pl: 1
                    }}
                >
                    AlphaDAO
                </Typography>
                <Typography sx={{ color: grey[300], fontSize: 18, mt: 2, pl: { xs: 0, sm: 0, md: 7.5, lg: 10 } }}>
                    AlphaDAO, a groundbreaking decentralized project on the BSC blockchain, revolutionizes the leasing, lending and
                    borrowing landscape for ERC20 tokens. As the world`s premier peer-to-peer lending and borrowing platform, AlphaDAO
                    empowers users to customize borrow rates, select assets, and determine durations for seamless and personalized
                    transactions.
                </Typography>
                <Typography sx={{ color: grey[300], fontSize: 18, mt: 2, pl: { xs: 0, sm: 0, md: 7.5, lg: 10 } }}>
                    The AlphDAO platform is designed with user-friendly interfaces, robust security measures, and efficient smart contracts
                    to provide a seamless and secure leasing, lending and borrowing experience. Explore the future of decentralized finance
                    with AlphaDAO, where users have the power to shape the protocol and benefit from a cutting-edge lending and borrowing
                    ecosystem.
                </Typography>
            </Grid>
            <Grid container lg={6}>
                <Typography
                    sx={{
                        color: grey[50],
                        fontSize: { xs: 28, sm: 28, md: 36, lg: 40 },
                        fontWeight: 505,
                        pt: 4,
                        pl: { xs: 0, sm: 0, md: 7.5, lg: 10 }
                    }}
                >
                    The
                </Typography>
                <Typography
                    textAlign="left"
                    sx={{
                        backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                        fontSize: { xs: 28, sm: 28, md: 36, lg: 40 },
                        letterSpacing: '1px',
                        backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                        backgroundSize: '100%',
                        backgroundRepeat: 'repeat',
                        fontWeight: 505,
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        pl: 1,
                        pt: 4
                    }}
                >
                    story
                </Typography>
                <Typography
                    sx={{
                        color: grey[50],
                        fontSize: { xs: 28, sm: 28, md: 36, lg: 40 },
                        fontWeight: 505,
                        pt: 4,
                        pl: 1
                    }}
                >
                    of AlphaDAO
                </Typography>
                <Typography sx={{ color: grey[300], fontSize: 18, mt: 2, pl: { xs: 0, sm: 0, md: 7.5, lg: 10 } }}>
                    AlphaDAO`s journey unfolded in the scenic landscapes of Switzerland in January 2024. Driven by a vision for pioneering
                    change in the decentralized finance landscape, the team embarked on a mission to create the world`s first peer-to-peer
                    leasing, lending and borrowing platform. Setting a new standard, AlphaDAO prioritized fully decentralized governance and
                    transparent actions, aiming to redefine the lending and borrowing experience.
                </Typography>
                <Typography sx={{ color: grey[300], fontSize: 18, mt: 2, pl: { xs: 0, sm: 0, md: 7.5, lg: 10 } }}>
                    Motivated by the principles of true decentralization, the team dedicated themselves to constructing a platform that
                    would benefit everyone in the ecosystem. In a departure from existing lending and borrowing platforms, AlphaX DAO sought
                    to provide a refreshing alternative, introducing innovative features that align with the ethos of decentralized finance.
                </Typography>
            </Grid>
            <Grid container xs={12} lg={12} alignItems="center" sx={{ pl: { xs: 0, sm: 0, md: 5, lg: 10 } }}>
                <Grid item width="100%">
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f9774b, #f9774b)`,
                            fontSize: { xs: 25, sm: 28, md: 36, lg: 40 },
                            backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f9774b, #f9774b)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            fontWeight: 705,
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            pt: 4
                        }}
                    >
                        Assets
                    </Typography>
                    <Typography fontSize={16} sx={{ color: grey[400], mt: 2 }}>
                        Please use suitable logos for light and dark backgrounds as shown below.
                    </Typography>
                    <Typography fontSize={16} sx={{ color: grey[100], mt: 5 }}>
                        Official logo variants
                    </Typography>
                </Grid>
                <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    justifyContent="left"
                    lg={3}
                    md={3.3}
                    alignItems="center"
                    sx={{ borderRadius: 2, mt: 2, bgcolor: grey[700] }}
                >
                    <Grid container xs={4} p={1}>
                        <Grid item sx={{ bgcolor: grey[800], boxShadow: 3, borderRadius: 2, p: 2 }}>
                            <img src={alxlogo} alt="logo" style={{ objectFit: 'cover', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container xs={7} p={1}>
                        <Grid item xs={12}>
                            <Typography sx={{ color: grey[200] }}>Colored</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ color: '#2c81e4', fontWeight: 905 }}>PNG</Typography>
                        </Grid>
                        <Grid item mx={1}>
                            <Typography sx={{ color: grey[400], fontSize: 18, fontWeight: 405 }}>|</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ color: '#2c81e4', fontWeight: 905 }}>SVG</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    justifyContent="left"
                    lg={3}
                    md={3.3}
                    alignItems="center"
                    sx={{ borderRadius: 2, mt: 2, bgcolor: grey[700], ml: { xs: 0, sm: 0, md: 7.5, lg: 10 } }}
                >
                    <Grid container xs={4} p={1}>
                        <Grid item sx={{ bgcolor: grey[800], boxShadow: 3, borderRadius: 2, p: 2 }}>
                            <img src={alxwhitelogo} alt="logo" style={{ objectFit: 'cover', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container xs={7} p={1}>
                        <Grid item xs={12}>
                            <Typography sx={{ color: grey[200] }}>White</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ color: '#2c81e4', fontWeight: 905 }}>PNG</Typography>
                        </Grid>
                        <Grid item mx={1}>
                            <Typography sx={{ color: grey[400], fontSize: 18, fontWeight: 405 }}>|</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ color: '#2c81e4', fontWeight: 905 }}>SVG</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    lg={3}
                    md={3.3}
                    justifyContent="left"
                    alignItems="center"
                    sx={{ borderRadius: 2, mt: 2, bgcolor: grey[700], ml: { xs: 0, sm: 0, md: 5, lg: 10 } }}
                >
                    <Grid container xs={4} p={1}>
                        <Grid item sx={{ bgcolor: grey[800], boxShadow: 3, borderRadius: 2, p: 2 }}>
                            <img src={alxblacklogo} alt="logo" style={{ objectFit: 'cover', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container xs={7} p={1}>
                        <Grid item xs={12}>
                            <Typography sx={{ color: grey[200] }}>Black</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ color: '#2c81e4', fontWeight: 905 }}>PNG</Typography>
                        </Grid>
                        <Grid item mx={1}>
                            <Typography sx={{ color: grey[400], fontSize: 18, fontWeight: 405 }}>|</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ color: '#2c81e4', fontWeight: 905 }}>SVG</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container xs={12} mt={5}>
                    <Typography fontSize={18} sx={{ color: grey[300] }}>
                        To propose a feature or send partnership offer, reach out to info@alphadao.finance
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PressRoom;
