import React from 'react';
import { Grid, Box } from '@mui/material';

const GradientOrangeSquare = ({ pos }) => (
    <Grid container sx={{ width: '100%', display: 'flex', justifyContent: pos, zIndex: 989 }}>
        <Box
            sx={{
                background: 'linear-gradient(to bottom right, #d06148, #6A2914)',
                height: '10rem',
                border: 1,
                borderColor: '#EB7D65',
                boxShadow: '0px 0px 100px rgba(208, 97, 72, 1)',
                width: '10rem',
                transform: 'rotate(40deg)',
                borderRadius: 8,
                mr: -5
            }}
        />
    </Grid>
);

export default GradientOrangeSquare;
