// material-ui
import { Grid, IconButton, Typography, Fade, Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import GradientCircle from './GradientCircle';
import CollapseCardFeatures from './CollapsedCardFeatures';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { gradintBackgroundLight } from 'themes/constants';
import GradientSquareCut from './GradientSquareCut';
import HeadTypography from './HeadTypography';
import GradientHeadTypography from './GradientHeadTypography';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const EasyToStart = () => {
    const texts = [
        {
            title: (
                <Typography sx={{ fontSize: 18 }}>
                    We have built the first-ever P2P based lease, lend and borrow platform. The intentions behind the project were and will
                    always be decentralization and true governance.
                </Typography>
            ),
            name: <Typography sx={{ fontSize: 18 }}>Y.G.</Typography>,
            role: <Typography sx={{ fontSize: 18 }}>Founder of AlphaDAO</Typography>
        },
        {
            title: (
                <Typography sx={{ fontSize: 18 }}>
                    The final goal of AlphaDAO is creating a platform with all of your favourite decentralized services.
                </Typography>
            ),
            name: <Typography sx={{ fontSize: 18 }}>Y.G.</Typography>,
            role: <Typography sx={{ fontSize: 18 }}>Founder of AlphaDAO</Typography>
        }
    ];
    const [checked, setChecked] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [action, setAction] = useState(null); // "next" or "prev"

    const handleButtonClick = (direction) => {
        setAction(direction);
        setChecked(false);
    };

    const handleExited = () => {
        setCurrentIndex((prevIndex) => {
            if (action === 'next') {
                return (prevIndex + 1) % texts.length;
            }
            if (action === 'prev') {
                return (prevIndex - 1 + texts.length) % texts.length;
            }
            return prevIndex;
        });
        setAction(null);
        setChecked(true);
        console.log(currentIndex);
    };
    return (
        <>
            <Grid container display="flex" justifyContent="center" width="100%" alignItems="center">
                <Grid
                    container
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 20,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <GradientCircle size={250} color="#c94378" />
                </Grid>
                <Grid item width="100%">
                    <CollapseCardFeatures />
                </Grid>
                <Grid
                    container
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        right: 0,
                        overflow: 'hidden',
                        mt: { lg: 20, md: 20, sm: 0, xs: -10 }
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: '50%',
                            mr: -10,
                            height: { lg: 220, md: 200, sm: 180, xs: 150 },
                            width: { lg: 220, md: 200, sm: 180, xs: 150 },
                            background: 'linear-gradient(to bottom right, #E56F54, #6A2914, #E56F54)',
                            display: 'flex',
                            border: 1,
                            borderColor: '#FF9D86',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: '50%',
                                height: '50%',
                                borderTop: 1,
                                borderColor: '#FF9D86',
                                transform: 'rotate(310deg)',
                                width: '50%',
                                background: gradintBackgroundLight
                            }}
                        />
                    </Box>
                </Grid>
                <HeadTypography text="Easy to lend, " />
                <GradientHeadTypography text="easy to borrow" />
                <Grid
                    container
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: { lg: 'left', md: 'center', sm: 'left', xs: 'left' },
                        mt: -20,
                        zIndex: -1
                    }}
                >
                    <GradientCircle size={250} color="#C94378" />
                </Grid>
                <Grid container width="100%" display="flex" flexDirection="column">
                    <Typography color={grey[400]} textAlign="center" fontSize={18} letterSpacing="1px" zIndex={995} px={2}>
                        Powerful P2P platform enhancing flexibility
                    </Typography>
                    <Typography color={grey[400]} textAlign="center" zIndex={995} fontSize={18} letterSpacing="1px" px={2}>
                        You can get started by leasing, lending or borrowing any BEP20 token in an instance
                    </Typography>
                </Grid>
                <Grid item width="92.5%" display="flex" justifyContent="center" my={2}>
                    <Button
                        size="small"
                        sx={{
                            background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                            borderRadius: 50,
                            textTransform: 'none',
                            fontWeight: 600,
                            fontSize: 20,
                            height: 50,
                            color: grey[100],
                            width: 300
                        }}
                    >
                        Learn more
                    </Button>
                </Grid>
                <GradientSquareCut />
                <Grid
                    container
                    lg={3}
                    xs={11}
                    md={4}
                    sm={7}
                    sx={{
                        bgcolor: 'rgba(40, 36, 43, 0.5)',
                        backdropFilter: 'blur(4px)',
                        p: 3,
                        mt: 2,
                        borderRadius: 4,
                        boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
                        zIndex: 995
                    }}
                >
                    <Fade in={checked} timeout={1000} onExited={handleExited}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                            <Typography variant="h3" letterSpacing="1px" sx={{ color: grey[50] }}>
                                {texts[currentIndex].title}
                            </Typography>
                            <Typography variant="h3" letterSpacing="1px" sx={{ color: grey[50], mt: 20 }}>
                                {texts[currentIndex].name}
                            </Typography>
                            <Grid item width="100%">
                                <Typography variant="h3" letterSpacing="1px" sx={{ color: grey[400] }}>
                                    {texts[currentIndex].role}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
                <Grid container sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 5 }}>
                    <IconButton
                        onClick={() => handleButtonClick('prev')}
                        sx={{
                            width: 50,
                            height: 50,
                            mx: 1,
                            bgcolor: 'transparent',
                            border: 1,
                            borderColor: '#C5D1FF',
                            ':hover': { boxShadow: '2px 0px 60px #ce447a' }
                        }}
                    >
                        <KeyboardArrowLeftIcon sx={{ color: grey[200] }} />
                    </IconButton>
                    <IconButton
                        onClick={() => handleButtonClick('next')}
                        sx={{
                            width: 50,
                            height: 50,
                            mx: 1,
                            bgcolor: 'transparent',
                            border: 1,
                            borderColor: '#C5D1FF',
                            ':hover': { boxShadow: '2px 0px 60px #ce447a' }
                        }}
                    >
                        <KeyboardArrowRightIcon sx={{ color: grey[200] }} />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

export default EasyToStart;
