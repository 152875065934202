// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import alxlogo from '../../assets/alxlogo.svg';
import { grey } from '@mui/material/colors';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const WhitepaperCard = () => {
    const theme = useTheme();
    return (
        <>
            <Grid item container sx={{ justifyContent: 'center', display: 'flex', p: 2, mt: 10 }}>
                <Grid xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', alignItems: 'center', mt: 10, mb: 5 }}>
                    <img src={alxlogo} alt="AlphaXlogo" width={50} height={50} />
                    <Typography
                        sx={{
                            color: grey[50],
                            fontSize: { lg: 50, md: 50, sm: 40, xs: 25 },
                            fontWeight: 505,
                            ml: 2
                        }}
                    >
                        AlphaDAO Whitepaper
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        Vision
                    </Typography>
                </Grid>
                <Grid container xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2 }}>
                    <Typography textAlign="left" fontSize={18} color={theme.palette.grey[300]}>
                        The primary vision of AlphaDAO is to create a decentralized platform that provides access to financial services for
                        everyone. By eliminating the middleman, the platform ensures that users can engage with it using only an internet
                        connection and a web3 wallet.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        Mission
                    </Typography>
                </Grid>
                <Grid container xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', pl: 2, display: 'flex' }}>
                    <Typography textAlign="left" fontSize={18} color={theme.palette.grey[300]}>
                        AlphaDAO pioneers the first lend and borrow platform and lease platform that are both peer-to-peer (P2P) based,
                        allowing the integration of any ERC20 token. The mission is to decentralize financial services by leveraging
                        blockchain technology to build a secure, autonomous, and inclusive financial ecosystem.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        Problem and solution
                    </Typography>
                </Grid>
                <Grid container xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', pl: 2, display: 'flex' }}>
                    <Typography textAlign="left" fontSize={18} color={theme.palette.grey[300]}>
                        The traditional financial system often requires intermediaries, leading to increased costs, reduced efficiency, and
                        limited accessibility. AlphaDAO addresses these issues by offering a completely decentralized platform where users
                        can lease, lend, and borrow digital assets directly with one another, ensuring transparency, security, and
                        inclusivity.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 6, mb: 2 }}>
                    <Typography fontSize={40} fontWeight={700} sx={{ color: grey[50] }}>
                        Table of contents
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        1. Token information
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', pl: 5, display: 'flex', flexDirection: 'column' }}
                >
                    <Typography textAlign="left" fontSize={18} color={theme.palette.grey[300]}>
                        1.1 Token data
                    </Typography>
                    <Typography textAlign="left" fontSize={18} color={theme.palette.grey[300]}>
                        1.2 Utilities
                    </Typography>
                    <Typography textAlign="left" fontSize={18} color={theme.palette.grey[300]}>
                        1.3 Token distribution
                    </Typography>
                    <Typography textAlign="left" fontSize={18} color={theme.palette.grey[300]}>
                        1.4 Burn mechanism
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        2. AlphaDAO architecture
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', pl: 5, display: 'flex', flexDirection: 'column' }}
                >
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        2.1 Lease platform
                    </Typography>
                    <Typography textAlign="left" pl={2} fontSize={18} color={theme.palette.grey[300]}>
                        - Function
                    </Typography>
                    <Typography textAlign="left" pl={2} fontSize={18} color={theme.palette.grey[300]}>
                        - Process
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        3. Decentralized features
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', pl: 5, display: 'flex', flexDirection: 'column' }}
                >
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        3.1 Autonomy
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        3.2 User-Driven Decisions
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        4. Lease platform
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', pl: 5, display: 'flex', flexDirection: 'column' }}
                >
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        4.1 Operation
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        4.2 Process
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        4.3 Example Use Case
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        5. Lend and borrow platform
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', pl: 5, display: 'flex', flexDirection: 'column' }}
                >
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        5.1 Operation
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        5.2 Process
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        5.3 Example Use Case
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        6. Tokenomics and incentives
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', pl: 5, display: 'flex', flexDirection: 'column' }}
                >
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        6.1 Sustainability
                    </Typography>
                    <Typography textAlign="left" pl={2} fontSize={18} color={theme.palette.grey[300]}>
                        - Revenue Generation
                    </Typography>
                    <Typography textAlign="left" pl={2} fontSize={18} color={theme.palette.grey[300]}>
                        - Buybacks and Burns
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        6.2 Incentives
                    </Typography>
                    <Typography textAlign="left" pl={2} fontSize={18} color={theme.palette.grey[300]}>
                        - User Rewards
                    </Typography>
                    <Typography textAlign="left" pl={2} fontSize={18} color={theme.palette.grey[300]}>
                        - Competitive Offers
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 4, mb: 2 }}>
                    <Typography fontSize={22} fontWeight={600} sx={{ color: grey[50] }}>
                        7. AlphaDAO Auctions
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', pl: 5, display: 'flex', flexDirection: 'column' }}
                >
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        7.1 Distribution
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        7.2 Happy Pay Day
                    </Typography>
                    <Typography textAlign="left" fontWeight={600} fontSize={18} color={theme.palette.grey[300]}>
                        7.3 Fee Allocation
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 800,
                            fontSize: 40,
                            mt: 5,
                            backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        Title: AlphaDAO Whitepaper
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 26,
                            mt: 2,
                            ml: 0.5,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        1. Token information
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 2, mb: 2, flexDirection: 'column' }}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        1.1. Token data
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Name: AlphaDAO
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Symbol: ALX
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Decimals: 18
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Network: Binance Smart Chain (BSC)
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Initial supply: 100,000,000 ALX
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #E5E5E5, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        1.2. Token utilities
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Fee Discounts: Holding more than 5,000 ALX grants users a 40% discount on all platform fees, including those for the
                        Lease, Lend, and Borrow platforms.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #E5E5E5, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        1.3. Token distribution
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        PancakeSwap Liquidity: Upon deployment, liquidity is provided to PancakeSwap with 70,000,000 ALX.
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Lease Platform: 20,000,000 ALX are leased through the Lease platform.
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        AlphaDAO Auctions: 10,000,000 ALX are distributed through AlphaDAO Auctions.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #E5E5E5, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        1.4. Burn mechanism
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Auction Buybacks: 10% of USDT contributions from auctions are used to buy back and burn ALX tokens, sending them to
                        a dead wallet.
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Platform Fees: Fees from the Lease and Lend & Borrow platforms are also used to buy back and burn ALX tokens,
                        reducing the total supply over time and enhancing token value.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 26,
                            mt: 2,
                            ml: 0.5,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        2. AlphaDAO architecture
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 2, mb: 2, flexDirection: 'column' }}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        2.1. Lease platform
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Function
                    </Typography>
                    <Typography textAlign="left" pl={3} color={theme.palette.grey[300]} mt={2}>
                        Users create offers to lease any ERC20 token without token fees.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Process
                    </Typography>
                    <Typography textAlign="left" pl={3} color={theme.palette.grey[300]} mt={2}>
                        The offer creator sets the terms, including token types, amounts, payment schedules, and conditions. Tokens are
                        locked in smart contracts and transferred based on adherence to the terms.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #E5E5E5, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        2.2. Lend and borrow platform
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Function
                    </Typography>
                    <Typography textAlign="left" pl={3} color={theme.palette.grey[300]} mt={2}>
                        Users create offers to lend ERC20 tokens, setting terms for collateral and repayment.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Process
                    </Typography>
                    <Typography textAlign="left" pl={3} color={theme.palette.grey[300]} mt={2}>
                        Collateral is locked in a smart contract. If repayment conditions are met, the collateral is returned. If not, the
                        offer creator retains the collateral.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #E5E5E5, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        2.3. Software & Technology
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Built on Binance Smart Chain using blockchain technology.
                    </Typography>
                    <Typography textAlign="left" pl={2} color={theme.palette.grey[300]} mt={2}>
                        Utilizes decentralized systems to ensure autonomy and security.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 26,
                            mt: 2,
                            ml: 0.5,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        3. Decentralized features
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 2, mb: 2, flexDirection: 'column' }}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        3.1. Autonomy
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        The platform is completely autonomous, removing the need for intermediaries.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Users control their actions, such as creating and accepting offers, setting terms, and managing transactions.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        3.2. User-Driven Decisions
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Decisions on leasing, lending, and borrowing are made by users based on predefined rules set by offer creators.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        The platform’s design ensures that no single entity or individual has more rights or control than others.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 26,
                            mt: 2,
                            ml: 0.5,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        4. Lease platform
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 2, mb: 2, flexDirection: 'column' }}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        4.1. Operation
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        The Lease platform is a P2P-based system, accessible to anyone with a web3 wallet and an internet connection. Users
                        can create offers specifying:
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Supplied Token: The ERC20 token to lease.
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Payment Token: Options include USDC, USDT, DAI, WETH, BTCB, and SOL.
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Payment Terms: The number of payments (minimum 2) and days between payments (minimum 7).
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Conditions: The offer creator sets the price by specifying the supplied token amount and payment token amount.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        4.2. Process
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        1. Offer Creation: The user sets the terms and locks the supplied token in a smart contract.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        2. Offer Acceptance: Other users can lease the token by making an upfront payment.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        3. Payment Schedule: Lessees must follow the payment schedule. Late payments over 72 hours allow the offer creator
                        to reclaim the token.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        4. Completion: Successful payments unlock the token for the lessee, who adhered to the terms. Partial leases are
                        possible, allowing flexibility in the offer.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        4.3. Example use case
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        A user wants to lease 5 WETH. They create an offer specifying the payment terms (e.g., 3 payments, 14 days apart)
                        and the price in USDC. Another user accepts the offer and makes the upfront payment. The leased WETH is locked in a
                        smart contract and transferred according to the payment schedule. If all payments are made on time, the lessee gains
                        full access to the WETH. If a payment is late by more than 72 hours, the original offer creator can reclaim the
                        WETH.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Additionally, the lease platform allows lessees to reserve tokens at a predetermined price. If the market price of
                        the supplied token increases during the lease period, the lessee benefits from the price appreciation. For example,
                        if the lessee reserves WETH at $1,000 per token and the price increases to $1,500 by the end of the lease, the
                        lessee gains significant value, having secured the token at a lower cost. This potential for appreciation is a major
                        advantage, making the leasing platform attractive for strategic investments.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 26,
                            mt: 2,
                            ml: 0.5,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        5. Lend and borrow platform
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 2, mb: 2, flexDirection: 'column' }}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        5.1. Operation
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        The Lend and Borrow platform is a P2P-based, trustless system without intermediaries. Users can create offers
                        specifying:
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Supplied Token: The ERC20 token to lend.
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Collateral Token: Options include USDT, USDC, DAI, WETH, BTCB, and SOL.
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Grace Period: The time allowed for repayment before the collateral is forfeited.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        5.2. Process
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        1. Offer Creation: The user sets the terms and locks the supply token in a smart contract.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        2. Offer Acceptance: Borrowers provide the specified collateral.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        3. Collateral Management: If repayment is made within the grace period, the collateral is returned. If not, the
                        offer creator retains the collateral.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        5.3. Example use case
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        A user wants to lend 1000 USDT and requires 1 WETH as collateral. They create an offer specifying the repayment
                        terms and grace period. Another user accepts the offer and provides the WETH collateral. The USDT is transferred to
                        the borrower, while the WETH is locked in a smart contract. If the borrower repays the 1000 USDT within the grace
                        period, the WETH is returned. If not, the lender keeps the WETH.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 26,
                            mt: 2,
                            ml: 0.5,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        6. Tokenomics and incentives
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 2, mb: 2, flexDirection: 'column' }}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        6.1. Sustainability
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Revenue Generation
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Fees generated from platform transactions are reinvested into the platform for development, maintenance, and
                        improvements.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Buybacks and Burns
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        A portion of the fees is used to buy back and burn ALX tokens, reducing the total supply and increasing token value
                        over time.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        6.2. Incentives
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        User rewards
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Users might receive rewards for participating in leasing, lending, and borrowing activities on random principles.
                        The required funds for that are being distribute from the development & marketing funds.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Competitive offers
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Fees are also used to secure and promote competitive offers on the platform, enhancing user experience.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} md={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', mt: 2 }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 26,
                            mt: 2,
                            ml: 0.5,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        7. AlphaDAO Auctions
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={10}
                    md={12}
                    sm={12}
                    sx={{ justifyContent: 'left', display: 'flex', pl: 2, mt: 2, mb: 2, flexDirection: 'column' }}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        7.1. Distribution
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Daily Auctions: 20,000,000 ALX are distributed over 100 days, with 200,000 ALX allocated daily.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        USDT Participation: Users participate in auctions using USDT, and the daily ALX price is determined by the total
                        USDT contributed divided by 200,000 ALX. A wallet address may participate only once per day but can participate
                        every day until the AlphaDAO Auctions end.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        7.2. Happy Pay Day
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Eligibility
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Users must contribute USDT (the min. is 50 USDT and the max is 10,000 USDT) and claim their ALX tokens in order to
                        become eligible for a Happy Pay Day.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Paybacks
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Eligible users receive random USDT paybacks (up to 100% of their contribution per Happy Pay Day). 80% of daily USDT
                        contributions form the payback pool, with a minimum of 50 paybacks daily and a cap of 2% per user.
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Happy Pay Days start from AlphaDAO Auctions day 3 and there will be a minimum of 50 HPDs each and every day. A user
                        might receive more than one HPD per day.
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundcolor: 'primary',
                            fontWeight: 905,
                            fontSize: 22,
                            mt: 2,
                            backgroundImage: `linear-gradient(45deg, #FFFFFF, #3F3F3F)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        7.3. Fee allocation
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Buybacks and Burns
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        10% of USDT contributions from auctions are used to buy back and burn ALX tokens, reducing the circulating supply
                        and enhancing token value.
                    </Typography>
                    <Typography textAlign="left" pl={2} fontWeight={700} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        Future developments
                    </Typography>
                    <Typography textAlign="left" pl={4} fontWeight={600} fontSize={18} color={theme.palette.grey[300]} mt={2}>
                        10% of USDT contributions are allocated for future developments and additional buybacks, ensuring continuous
                        improvement and sustainability.
                    </Typography>
                    <Typography textAlign="left" fontWeight={700} fontSize={24} my={15} color={theme.palette.grey[100]}>
                        This detailed whitepaper outlines the decentralized and autonomous nature of AlphaDAO, emphasizing its technical
                        aspects, user-driven features, and sustainable economic model. The platform aims to revolutionize access to
                        financial services through blockchain technology, offering transparency, security, and inclusivity for all users.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default WhitepaperCard;
