import React from 'react';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

// Custom Typography Component
const HeadTypography = ({ text, ...props }) => (
    <Typography
        sx={{
            color: grey[50],
            fontSize: { lg: 60, md: 50, sm: 40, xs: 30 },
            fontWeight: 605,
            ...props.sx
        }}
        {...props}
    >
        {text}
    </Typography>
);

export default HeadTypography;
