import { motion } from 'framer-motion';
import { useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { FiArrowUp, FiLink, FiTarget, FiUpload } from 'react-icons/fi';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { cardcolor } from 'themes/constants';
import HeadTypography from './HeadTypography';
import GradientHeadTypography from './GradientHeadTypography';

const features = [
    {
        title: 'Any BEP20 token',
        Icon: FiUpload,
        description: 'Users are able to lease, supply or borrow any BEP20 token through a custom offer.'
    },
    {
        title: 'Flexible terms',
        Icon: FiArrowUp,
        description: 'The borrow rate is set in advance by the offer creator with no limitations or requirements to the rate.'
    },
    {
        title: 'Unlimited amount',
        Icon: FiTarget,
        description: 'Any amount of BEP20 tokens could be leased, supplied or borrowed by the users.'
    },
    {
        title: '50+ integrations',
        Icon: FiLink,
        description: 'More than 50 BEP20 tokens are currently available for borrowing through the AlphaDAO platforms.'
    }
];

const CollapseCardFeatures = () => {
    const [position, setPosition] = useState(0);

    const shiftLeft = () => {
        if (position > 0) {
            setPosition((pv) => pv - 1);
        }
    };

    const shiftRight = () => {
        if (position < features.length - 1) {
            setPosition((pv) => pv + 1);
        }
    };

    <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
            <stop stopColor="#7f42df" offset="0%" />
            <stop stopColor="#e25b65" offset="100%" />
        </linearGradient>
    </svg>;

    return (
        <Box sx={{ overflow: 'hidden', bgcolor: 'neutral.100', px: 4, pb: 12 }}>
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12} md={8} lg={9} sm={8} display="flex" flexDirection="row" mt={10}>
                    <HeadTypography text="We are " mr={1} />
                    <GradientHeadTypography text="here to stay" />
                </Grid>
                <Grid
                    container
                    md={4}
                    sm={4}
                    lg={3}
                    sx={{
                        justifyContent: { lg: 'flex-end', md: 'flex-end', sm: 'flex-end' },
                        display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'none' }
                    }}
                    pt={10}
                >
                    <Button
                        onClick={shiftLeft}
                        sx={{
                            m: 1,
                            bgcolor: grey[50],
                            width: 60,
                            height: 60,
                            border: 0,
                            borderColor: '#DD3375',
                            borderRadius: '50%',
                            boxShadow: 0,
                            ':hover': { border: 2, borderColor: '#DD3375', bgcolor: grey[50], boxShadow: '2px 0px 60px #ce447a' }
                        }}
                    >
                        <KeyboardArrowLeftIcon
                            sx={{ fill: { sm: '#e25b65', md: 'url(#blue-gradient)', lg: 'url(#blue-gradient)' }, fontSize: 35 }}
                        />
                    </Button>
                    <Button
                        onClick={shiftRight}
                        sx={{
                            m: 1,
                            bgcolor: grey[50],
                            width: 60,
                            borderRadius: '50%',
                            height: 60,
                            border: 0,
                            borderColor: '#DD3375',
                            boxShadow: 0,
                            ':hover': { border: 2, borderColor: '#DD3375', bgcolor: grey[50], boxShadow: '2px 0px 60px #ce447a' }
                        }}
                    >
                        <KeyboardArrowRightIcon
                            sx={{ fill: { sm: '#e25b65', md: 'url(#blue-gradient)', lg: 'url(#blue-gradient)' }, fontSize: 35 }}
                        />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', gap: 4 }}>
                        {features.map((feat, index) => (
                            <Feature {...feat} key={index} position={position} index={index} />
                        ))}
                    </Box>
                </Grid>
                <Grid
                    container
                    xs={12}
                    sx={{
                        justifyContent: { xs: 'center' },
                        display: { lg: 'none', md: 'none', sm: 'none', xs: 'flex' }
                    }}
                    pt={3}
                >
                    <Button
                        onClick={shiftLeft}
                        sx={{
                            m: 1,
                            bgcolor: grey[50],
                            width: 60,
                            height: 60,
                            border: 0,
                            borderColor: '#DD3375',
                            borderRadius: '50%',
                            boxShadow: 0,
                            ':hover': { border: 2, borderColor: '#DD3375', bgcolor: grey[50], boxShadow: '2px 0px 60px #ce447a' }
                        }}
                    >
                        <KeyboardArrowLeftIcon sx={{ fill: '#e25b65', fontSize: 30 }} />
                    </Button>
                    <Button
                        onClick={shiftRight}
                        sx={{
                            m: 1,
                            bgcolor: grey[50],
                            width: 60,
                            borderRadius: '50%',
                            height: 60,
                            border: 0,
                            borderColor: '#DD3375',
                            boxShadow: 0,
                            ':hover': { border: 2, borderColor: '#DD3375', bgcolor: grey[50], boxShadow: '2px 0px 60px #ce447a' }
                        }}
                    >
                        <KeyboardArrowRightIcon sx={{ fill: '#e25b65', fontSize: 30 }} />
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const Feature = ({ position, index, title, description, Icon }) => {
    const translateAmt = position >= index ? index * 100 : index * 100 - 100 * (index - position);

    return (
        <motion.div
            animate={{ x: `${-translateAmt}%` }}
            transition={{ ease: 'easeInOut', duration: 0.35 }}
            sx={{ minWidth: '250px', width: '10/12', maxWidth: 'lg', flexGrow: 0 }}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    borderRadius: 3,
                    width: { lg: 450, md: 450, sm: 350, xs: 250 },
                    height: { lg: 200, md: 200, sm: 200, xs: 230 },
                    p: 3,
                    boxShadow: 'inset 0 0 40px rgba(255, 255, 255, 0.3)',
                    bgcolor: index % 2 ? '#28242b' : grey[200],
                    color: index % 2 ? grey[50] : '#28242b',
                    backdropFilter: 'blur(10px)'
                }}
            >
                <Icon sx={{ position: 'absolute', right: 2, top: 2, fontSize: '7xl', opacity: 1 }} />
                <Typography fontSize={26} className="mb-8 text-3xl font-bold">
                    {title}
                </Typography>
                <Typography sx={{ color: index % 2 ? grey[400] : cardcolor }}>{description}</Typography>
            </Card>
        </motion.div>
    );
};

export default CollapseCardFeatures;
