// material-ui
import { useTheme } from '@mui/styles';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FaXTwitter } from 'react-icons/fa6';
import GradientCircle from './GradientCircle';
import HeadTypography from './HeadTypography';
import GradientHeadTypography from './GradientHeadTypography';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const JoinTheCommunity = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container display="flex" justifyContent="center" width="100%" alignItems="center" mt={-20}>
                <Grid
                    container
                    sx={{
                        position: 'relative',
                        top: 150,
                        right: { lg: 400, md: 200, sm: 200, xs: 150 },
                        display: 'flex',
                        zIndex: -1,
                        justifyContent: 'right'
                    }}
                >
                    <GradientCircle size={250} color="#f13c58" />
                </Grid>
                <HeadTypography text="Join " />
                <GradientHeadTypography text="the community" />
                <Grid container width="90%" display="flex" justifyContent="space-evenly" borderRadius={3}>
                    <Grid item width="100%" display="flex" justifyContent="center" mb={4}>
                        <Typography color={grey[400]} textAlign="center" fontSize={18} width="45rem" px={2} mt={3}>
                            Learn more about Alpha, chat with the team, others in the community, and have your say in shaping the future of
                            decentralized finance.
                        </Typography>
                    </Grid>
                    {/* Social Icons */}
                    <Grid container width="100%" justifyContent="center" display="flex" mb={5}>
                        <IconButton
                            href="https://x.com/alphadaotoken"
                            sx={{
                                bgcolor: 'transparent',
                                border: 1,
                                fontWeight: 700,
                                width: 60,
                                height: 60,
                                borderColor: grey[50],
                                color: grey[50],
                                ':hover': { boxShadow: '2px 0px 60px #ce447a', borderColor: grey[50], border: 2 }
                            }}
                        >
                            <FaXTwitter style={{ color: grey[200], fontSize: 30 }} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container sx={{ width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '92.15%' } }}>
                    <Button
                        href="https://t.me/alphadao_token/"
                        size="small"
                        sx={{
                            background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                            borderRadius: 50,
                            textTransform: 'none',
                            fontWeight: 600,
                            fontSize: 20,
                            height: 50,
                            color: grey[100],
                            width: { lg: 300, md: 300, sm: '100%', xs: '100%' }
                        }}
                    >
                        Join us
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default JoinTheCommunity;
