import React from 'react';
import { Box } from '@mui/material';

const GradientCircle = ({ size, color }) => (
    <Box
        sx={{
            width: size,
            height: size,
            background: `radial-gradient(circle, ${color}, ${color}, transparent, transparent, transparent, transparent)`,
            borderRadius: '50%',
            filter: 'blur(55px)', // Adjust the blur amount as needed
            zIndex: 0
        }}
    />
);

export default GradientCircle;
