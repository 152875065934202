// material-ui
import { useTheme } from '@mui/styles';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FaXTwitter } from 'react-icons/fa6';
import GradientCircle from './GradientCircle';
import HeadTypography from './HeadTypography';
import GradientHeadTypography from './GradientHeadTypography';
import RoadmapStepper from './RoadmapStepper';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const Roadmap = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container display="flex" justifyContent="center" width="100%" alignItems="center" mt={-20}>
                <Grid
                    container
                    sx={{
                        position: 'relative',
                        top: 150,
                        right: { lg: 400, md: 200, sm: 200, xs: 150 },
                        display: 'flex',
                        zIndex: -1,
                        justifyContent: 'right'
                    }}
                >
                    <GradientCircle size={250} color="#f13c58" />
                </Grid>
                <GradientHeadTypography text="Roadmap" />
                <Grid container display="flex" justifyContent="center" mb={5}>
                    <Typography color={grey[400]} textAlign="center" fontSize={18} width="45rem" px={2} mt={3}>
                        The AlphaDAO roadmap outlines the strategic direction and key milestones of the decentralized project. It highlights
                        the phases from initial planning and development to implementation and continuous improvement
                    </Typography>
                </Grid>
                <RoadmapStepper />
            </Grid>
        </>
    );
};

export default Roadmap;
