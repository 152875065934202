// material-ui
import { useTheme } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { RiGovernmentLine, RiExchangeFundsLine, RiHandCoinLine } from 'react-icons/ri';
import GradientCircle from './GradientCircle';
import HeadTypography from './HeadTypography';

const GridItem = ({ title, description, IconComponent, linkTo }) => (
    <Grid
        container
        lg={3.5}
        md={5.75}
        sx={{ boxShadow: 3, borderRadius: 2, mt: 2, background: '#28242b', flexDirection: 'column', display: 'flex', p: 3 }}
    >
        <Grid container display="flex" alignItems="center" justifyContent="space-between">
            <Grid item width="85%">
                <Typography textAlign="left" variant="h6" pb={1} letterSpacing="1px">
                    {title}
                </Typography>
            </Grid>
            <Grid item width="15%">
                <IconComponent style={{ color: '#f9774b', fontSize: 35 }} />
            </Grid>
        </Grid>
        <Typography textAlign="left" color={grey[400]}>
            {description}
        </Typography>
        <Grid container display="flex" alignItems="center" mt={2}>
            <Button
                size="small"
                endIcon={<ArrowRightAltIcon style={{ fontSize: 30 }} />}
                component={Link}
                to={linkTo}
                sx={{
                    background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                    borderRadius: 50,
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: 14,
                    height: 40,
                    color: grey[100],
                    px: 3
                }}
            >
                Learn more
            </Button>
        </Grid>
    </Grid>
);

const Products = () => {
    const theme = useTheme();

    return (
        <>
            <Grid
                container
                display="flex"
                justifyContent="space-between"
                sx={{ width: { xs: '90%', md: '85%', sm: '90%', lg: '80%' } }}
                flexDirection="row"
                alignItems="center"
            >
                <Grid
                    item
                    width="60%"
                    sx={{ position: 'absolute', mt: { lg: -20, md: -45, sm: -60, xs: -70 }, display: 'flex', justifyContent: 'center' }}
                >
                    <GradientCircle size={250} color="#c94378" />
                </Grid>
                <Grid item width="100%" display="flex" justifyContent="center" mb={3}>
                    <HeadTypography text="Decentralized products" />
                </Grid>
                <GridItem
                    title="Lend Borrow platform"
                    description="Peer-to-peer lend and borrow platform to ensure better and custom market conditions."
                    IconComponent={RiHandCoinLine}
                    linkTo="/lendborrow"
                />
                <GridItem
                    title="Lease platform"
                    description="Peer-to-peer lease platform to ensure better market conditions and flexible lease system."
                    IconComponent={RiGovernmentLine}
                    linkTo="/otctrading"
                />
                <GridItem
                    title="Token Lock"
                    description="Platform to lock LP tokens or any kind of ERC20 token under custom conditions."
                    IconComponent={RiExchangeFundsLine}
                    linkTo="/bitcoinfund"
                />
            </Grid>
        </>
    );
};

export default Products;
