// material-ui
import { Button, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const Introduction = () => {
    const t = 23;

    return (
        <>
            <Grid container justifyContent="center" display="flex" mb={-5}>
                <Grid container lg={9} md={10} sm={9} sx={{ justifyContent: 'left', zIndex: 998, display: 'flex' }}>
                    <Typography
                        textAlign="left"
                        sx={{
                            px: { xs: 3, lg: 2 },
                            fontSize: { lg: 80, md: 60, sm: 40, xs: 30 },
                            fontWeight: 605,
                            color: grey[50]
                        }}
                    >
                        Your all-access pass to
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                            pr: { xs: 1, lg: 2 },
                            pl: { xs: 3, lg: 2 },
                            fontSize: { lg: 80, md: 60, sm: 40, xs: 30 },
                            mt: { lg: -5, md: -3, sm: -2, xs: -2 },
                            letterSpacing: '1px',
                            backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            fontWeight: 605,
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        decentralized
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            letterSpacing: '2px',
                            px: { xs: 0, lg: 2 },
                            mt: { lg: -5, md: -3, sm: -2, xs: -2 },
                            fontSize: { lg: 80, md: 60, sm: 40, xs: 30 },
                            fontWeight: 605,
                            color: grey[50]
                        }}
                    >
                        finance
                    </Typography>
                    <Grid container>
                        <Typography px={3} color={grey[200]} textAlign="left" mt={2} fontSize={20}>
                            Lease, lend and borrow any digital asset.
                        </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'left', px: 2, display: 'flex', mt: 5 }}>
                        <Button
                            href="https://app.alphadao.finance"
                            target="_blank"
                            size="small"
                            endIcon={<PlayArrowRoundedIcon style={{ fontSize: 30 }} />}
                            sx={{
                                background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                                borderRadius: 50,
                                textTransform: 'none',
                                fontWeight: 600,
                                fontSize: 20,
                                height: 50,
                                color: grey[100],
                                width: { lg: 300, md: 300, sm: '100%', xs: '100%' }
                            }}
                        >
                            Launch dApp
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Introduction;
