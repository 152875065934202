import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PressRoom from './PressRoom';
// project imports
// ==============================|| TYPOGRAPHY ||============================== //

const PressRoomMain = () => {
    useTheme();
    return (
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
            <PressRoom />
        </Grid>
    );
};

export default PressRoomMain;
