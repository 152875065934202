import React from 'react';
import { Grid } from '@mui/material';
import { gradintBackgroundLight } from 'themes/constants';

const GradientSquareCut = () => (
    <Grid container sx={{ position: 'relative', height: 0, justifyContent: 'left', top: 50, left: -50 }}>
        <Grid
            container
            sx={{
                borderRadius: 6,
                transform: 'rotate(20deg)',
                height: { lg: 200, md: 200, sm: 160, xs: 140 },
                background: `linear-gradient(to bottom right, #6438f3, #352375)`,
                width: { lg: 200, md: 200, sm: 160, xs: 140 },
                border: 0.6,
                borderColor: '#AA9BFF',
                zIndex: 995,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <Grid
                container
                sx={{
                    borderRadius: 6,
                    height: '60%',
                    width: '60%',
                    bgcolor: gradintBackgroundLight,
                    borderTop: 1,
                    borderLeft: 1,
                    borderColor: '#AA9BFF'
                }}
            />
        </Grid>
    </Grid>
);

export default GradientSquareCut;
