// material-ui
import { useTheme } from '@mui/styles';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import ThreeCircles from './3Circles';
import GradientOrangeSquare from './GradientOrangeSquare';
import GradientHeadTypography from './GradientHeadTypography';

const ExploreTheSystem = () => {
    const theme = useTheme();

    return (
        <>
            <Grid
                container
                lg={10}
                md={10}
                sm={10}
                xs={11}
                borderRadius={10}
                display="flex"
                justifyContent="center"
                sx={{ background: 'linear-gradient(to bottom, #100e11, #28242b)', pb: 10 }}
            >
                <Grid
                    container
                    md={6}
                    sm={8}
                    lg={5}
                    xs={6}
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'flex-end',
                        mt: -8,
                        pb: 20
                    }}
                >
                    <GradientOrangeSquare pos="right" />
                </Grid>
                <Paper
                    sx={{
                        bgcolor: 'rgba(23, 20, 24, 0.8)',
                        width: 600,
                        height: 'auto',
                        borderRadius: 7,
                        p: 2,
                        mx: 2,
                        boxShadow: '50px 50px 100px rgba(255, 87, 87, 0.5)',
                        zIndex: 995
                    }}
                >
                    <Box
                        sx={{
                            bgcolor: 'transparent',
                            width: '100%',
                            height: 35,
                            borderTopLeftRadius: 11,
                            borderTopRightRadius: 11,
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            pl: 1.5
                        }}
                    >
                        <Box sx={{ bgcolor: '#e64243', borderRadius: '50%', height: 13, width: 13 }} />
                        <Box sx={{ bgcolor: '#e3b00c', borderRadius: '50%', height: 13, width: 13, ml: 0.7 }} />
                        <Box sx={{ bgcolor: '#22c15e', borderRadius: '50%', height: 13, width: 13, ml: 0.7 }} />
                    </Box>
                    <Grid container display="flex" flexDirection="row" mt={1} ml={2}>
                        <Grid container width="auto">
                            <Typography sx={{ fontSize: 17, color: '#22c15e', letterSpacing: '1px' }}>~</Typography>
                        </Grid>
                        <Grid container width="90%" ml={1}>
                            <Typography sx={{ fontSize: 17, color: grey[300], letterSpacing: '1px' }}>
                                Lease and borrow with an ease. Any cryptocurrency token that you want. Instantly.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container display="flex" flexDirection="row" mt={1} ml={2}>
                        <Grid container width="auto">
                            <Typography sx={{ fontSize: 17, color: '#22c15e', letterSpacing: '1px' }}>~</Typography>
                        </Grid>
                        <Grid container width="90%" ml={1}>
                            <Typography sx={{ fontSize: 17, color: grey[300], letterSpacing: '1px' }}>
                                Decenralized and trustless. No middle-man in between.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container display="flex" flexDirection="row" mt={1} ml={2}>
                        <Grid container width="auto">
                            <Typography sx={{ fontSize: 17, color: '#22c15e', letterSpacing: '1px' }}>~</Typography>
                        </Grid>
                        <Grid container width="90%" ml={1} mb={4}>
                            <Typography sx={{ fontSize: 17, color: grey[300], letterSpacing: '1px' }}>
                                A growing ecosystem. A variety of different digital assets have been suppleid to AlphaDAO.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <ThreeCircles size={200} />
                <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Typography
                        textAlign="center"
                        sx={{ fontSize: { lg: 45, md: 40, sm: 30, xs: 30 }, color: grey[300], fontWeight: 600, mt: 20 }}
                    >
                        Find the offer that suits your requirements
                    </Typography>
                    <GradientHeadTypography centered text="Lend and borrow instantly" />
                </Grid>
            </Grid>
        </>
    );
};

export default ExploreTheSystem;
