/* eslint-disable no-underscore-dangle */
// material-ui
import { Button, Grid, IconButton, Typography } from '@mui/material';
// project imports
import LogoSection from '../LogoSection';
// assets
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import TelegramIcon from '@mui/icons-material/Telegram';
import SegmentIcon from '@mui/icons-material/Segment';
import { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, grey } from '@mui/material/colors';
import MenuDrawer from 'views/utilities/MenuDrawer';
import HeaderMenu from './HeaderMenu';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const Header = () => {
    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
    const [banner, setBanner] = useState(true);
    const openMenuDrawer = () => {
        setIsMenuDrawerOpen(true);
    };

    const closeMenuDrawer = () => {
        setIsMenuDrawerOpen(false);
    };

    return (
        <>
            {banner ? (
                <Grid
                    container
                    width="100vw"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        alignItems: 'center',
                        right: 0,
                        background: `linear-gradient(45deg, #9a48dc, #9a48dc, #9a48dc, #f13c58, #f13c58)`,
                        mb: 2,
                        py: 1,
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                >
                    <Typography textAlign="center">Make sure you are visiting:</Typography>
                    <Typography textAlign="center" pl={1} fontWeight={700}>
                        alphadao.finance
                    </Typography>
                    <IconButton
                        size="small"
                        sx={{ bgcolor: 'transparent', ml: 1 }}
                        onClick={() => {
                            setBanner(false);
                        }}
                    >
                        <CancelIcon sx={{ color: grey[100] }} />
                    </IconButton>
                </Grid>
            ) : null}
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 120,
                    px: { lg: 2, md: 2, sm: 2, xs: 2 },
                    py: 3,
                    mt: banner ? 7 : 0,
                    bgcolor: 'transparent'
                }}
            >
                <Grid container display="flex" flexDirection="row" alignItems="center" xs="auto" md={2.5} justifyContent="center" lg={2}>
                    <LogoSection />
                    <Typography variant="m1" sx={{ fontWeight: 505, pl: 2, color: grey[50], letterSpacing: '5px' }}>
                        AlphaDAO
                    </Typography>
                </Grid>
                <Grid container lg={5} md={5.2} sx={{ display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}>
                    <HeaderMenu />
                </Grid>
                <Grid container lg={3} md={3.7} alignItems="center" sx={{ display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}>
                    <Button
                        size="small"
                        href="https://app.alphadao.finance/"
                        target="_blank"
                        endIcon={<PlayArrowRoundedIcon style={{ fontSize: 30 }} />}
                        sx={{
                            background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                            borderRadius: 50,
                            textTransform: 'none',
                            fontWeight: 600,
                            fontSize: 16,
                            height: 50,
                            color: grey[100],
                            width: 200
                        }}
                    >
                        Launch dApp
                    </Button>
                    <svg width="0" height="0">
                        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                            <stop stopColor="#7f42df" offset="0%" />
                            <stop stopColor="#e25b65" offset="100%" />
                        </linearGradient>
                    </svg>
                    <IconButton
                        href="https://t.me/alphadao_token"
                        sx={{
                            bgcolor: grey[50],
                            height: 50,
                            width: 50,
                            ml: 2,
                            ':hover': { boxShadow: '2px 0px 60px #ce447a', borderColor: grey[50], backgroundColor: grey[50] }
                        }}
                    >
                        <TelegramIcon sx={{ fill: 'url(#blue-gradient)' }} />
                    </IconButton>
                </Grid>
                <Grid container alignItems="center" xs="auto" sx={{ display: { xs: 'flex', lg: 'none', sm: 'flex', md: 'none' } }}>
                    <IconButton onClick={openMenuDrawer} sx={{ bgcolor: 'transparent' }}>
                        <SegmentIcon sx={{ color: grey[50], fontSize: 30 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <MenuDrawer isOpen={isMenuDrawerOpen} onClose={closeMenuDrawer} />
        </>
    );
};

export default Header;
