import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme } from '@mui/system';
import { lightTheme } from 'themes/theme';
// routing
import Routes from 'routes';

// project imports
// ==============================|| APP ||============================== //
const App = () => {
    const theme = createTheme(lightTheme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
