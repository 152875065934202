export const mainColorUI = '#186049';
export const midColorUI = '#247158';
export const lightColorUI = '#56aa8f';
export const cremeColorUI = '#E4D5C7';
export const mainDarkUI = '#211F20';

export const main50 = '#f3faf7';
export const main100 = '#d8efe5';
export const main200 = '#b0dfcd';
export const main300 = '#81c7ae';
export const main400 = '#56aa8f';
export const main500 = '#3d8f76';
export const main600 = '#2f725f';
export const main700 = '#295c4e';
export const main800 = '#244b40';
export const main900 = '#223f37';
export const main950 = '#0f2420';
export const gradintBackgroundLight = '#100e11';
export const gradintBackgroundDark = '#100e11';
export const cardcolor = '#28242b';
