// material-ui
import { useTheme } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import { gradintBackgroundLight } from 'themes/constants';

const ThreeCircles = ({ size }) => {
    const theme = useTheme();

    return (
        <>
            <Grid container sx={{ width: '100%', display: 'flex', justifyContent: 'center', zIndex: 990, mt: -10 }}>
                <Grid container lg={6} display="flex" justifyContent="left" mb={-10}>
                    <Box
                        sx={{
                            background: 'linear-gradient(to bottom right, #d06148, #6A2914)',
                            height: size,
                            width: size,
                            transform: 'rotate(25deg)',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                background: gradintBackgroundLight,
                                height: '93%',
                                width: '93%',
                                transform: 'rotate(25deg)',
                                borderRadius: '50%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <Box
                                sx={{
                                    background: 'linear-gradient(to bottom right, #d06148, #6A2914)',
                                    height: '75%',
                                    width: '75%',
                                    transform: 'rotate(25deg)',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        background: gradintBackgroundLight,
                                        height: '90%',
                                        width: '90%',
                                        transform: 'rotate(25deg)',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background: 'linear-gradient(to bottom right, #d06148, #6A2914)',
                                            height: '60%',
                                            width: '60%',
                                            transform: 'rotate(25deg)',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                background: gradintBackgroundLight,
                                                height: '80%',
                                                width: '80%',
                                                transform: 'rotate(25deg)',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ThreeCircles;
