// material-ui
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import GradientCircle from './GradientCircle';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const FAQAccordion = () => {
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <Grid
                container
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'right',
                    mr: { lg: 85, md: 30, sm: 10 },
                    mt: 5,
                    zIndex: 995
                }}
            >
                <GradientCircle size={250} color="#C94378" />
            </Grid>
            <Grid container display="flex" justifyContent="center" alignItems="center" width="100%" mb={7} px={2}>
                <Grid item width="100%" mt={8} pb={2}>
                    <Typography
                        textAlign="center"
                        sx={{
                            backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                            fontSize: { lg: 60, md: 50, sm: 40, xs: 30 },
                            letterSpacing: '1px',
                            backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            fontWeight: 605,
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        FAQs
                    </Typography>
                </Grid>
                <Grid item lg={6.5} mb={5} sm={9} md={6}>
                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        disableGutters
                        sx={{ bgcolor: '#28242b', boxShadow: 0, color: grey[50] }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                            <Typography textAlign="left" fontSize={18} color={grey[50]} p={2}>
                                What is AlphaDAO?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography pb={5} fontSize={18} color={grey[400]}>
                                Launched in 2024, AlphaDAO is a distinguished DAO blockchain-enabled platform meticulously curated for the
                                seamless integration of decentralized finance. The platform pioneers the world`s first leasing, lending and
                                borrowing framework, allowing users to engage in peer-to-peer transactions with any ERC20 asset. Elevating
                                the user experience, AlphaDAO introduces decentralized services for everyone at anytime.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                        disableGutters
                        sx={{ bgcolor: '#28242b', mt: 2, boxShadow: 0 }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                            <Typography textAlign="left" fontSize={18} color={grey[50]} p={2}>
                                How does AlphaDAO work?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography pb={5} fontSize={18} color={grey[400]}>
                                AlphaDAO is decentralized autonomous organization which has been designed and built in a way where the
                                community expess their rights to vote and decide the future improvements and developments of the platform.
                                There is no person or entity that governs or dictates the rules of the projects.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel3'}
                        onChange={handleChange('panel3')}
                        disableGutters
                        sx={{ bgcolor: '#28242b', mt: 2, boxShadow: 0 }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                            <Typography textAlign="left" fontSize={18} color={grey[50]} p={2}>
                                What are ALX tokens?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography pb={5} fontSize={18} color={grey[400]}>
                                ALX is an ERC20 token and it has ALX symbol with 18 decimals. It is the main utility token of the project
                                and secures multiple benefits for its holders. ALX tokens might be held in any web3 wallet. Holding certain
                                amount of the ALX tokens secures discount on the Lease, Lend and Borrow platform fees.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel4'}
                        onChange={handleChange('panel4')}
                        disableGutters
                        sx={{
                            bgcolor: '#28242b',
                            mt: 2,
                            boxShadow: 0
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                            <Typography textAlign="left" fontSize={18} color={grey[50]} p={2}>
                                What digital assets do the AlphaDAO lease, lend and borrow platforms support?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography pb={5} fontSize={18} color={grey[400]}>
                                AlphaDAO lease, lend and borrow platforms allows everyone to lease, supply/borrow any digital asset. It is
                                the very first peer-to-peer lease, lend and borrow platform where users set up their own offers with their
                                own conditions unlocking infinite flexibility. The current version of the platform (v1.0) supports any ERC20
                                token.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel4'}
                        onChange={handleChange('panel4')}
                        disableGutters
                        sx={{
                            bgcolor: '#28242b',
                            mt: 2,
                            boxShadow: 0
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                            <Typography textAlign="left" fontSize={18} color={grey[50]} p={2}>
                                Who can use the AlphaDAO platforms?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography pb={5} fontSize={18} color={grey[400]}>
                                Everyone. There are no restrictions or middle-man during the process. Please make sure to get yourself
                                familiar with the detailed specifics of the `Lease` and `Lend and Borrow` platforms.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>
    );
};

export default FAQAccordion;
