import { useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import Introduction from './Introduction';
import AvailableNetworks from './AvailableNetworks';
import JoinTheCommunity from './JoinTheCommunity';
import FAQAccordion from './FAQAccordion';
import Products from './Products';
import Partners from './Partners';
import EasyToStart from './EasyToStart';
import ExploreTheSystem from './ExploreTheSystem';
import Roadmap from './Roadmap';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const [isLoading, setLoading] = useState(true);

    return (
        <>
            <Grid
                container
                width="100%"
                sx={{
                    pb: { lg: 10, md: 15, sm: 10, xs: 5 },
                    pt: 30
                }}
            >
                <Introduction isLoading={isLoading} />
            </Grid>
            <Grid container sx={{ justifyContent: 'center', mb: 17 }} mt={15} width="100%">
                <AvailableNetworks isLoading={isLoading} />
            </Grid>
            <Grid
                container
                sx={{
                    justifyContent: 'center',
                    mt: 10
                }}
                width="100%"
            >
                <ExploreTheSystem isLoading={isLoading} />
            </Grid>
            <Grid container sx={{ justifyContent: 'center' }}>
                <JoinTheCommunity isLoading={isLoading} />
            </Grid>
            <Grid container sx={{ justifyContent: 'center' }}>
                <EasyToStart isLoading={isLoading} />
            </Grid>
            <Grid container sx={{ justifyContent: 'center' }} width="100%">
                <FAQAccordion isLoading={isLoading} />
            </Grid>
            <Grid container sx={{ mt: 13, justifyContent: 'center' }} width="100%" mb={5}>
                <Products isLoading={isLoading} />
            </Grid>
            <Grid container sx={{ mt: 5, justifyContent: 'center' }} width="100%" mb={5}>
                <Roadmap isLoading={isLoading} />
            </Grid>
            <Grid container width="100%" justifyContent="center" pb={20} pt={10}>
                <Partners isLoading={isLoading} />
            </Grid>
        </>
    );
};

export default Dashboard;
