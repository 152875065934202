import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Auctions from './Auctions';
// project imports
// ==============================|| TYPOGRAPHY ||============================== //

const AuctionsMain = () => {
    useTheme();
    return (
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
            <Auctions />
        </Grid>
    );
};

export default AuctionsMain;
