import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, CssBaseline, Toolbar, Grid, Box } from '@mui/material';
// project imports
import Header from './Header';

// assets
import SubFooter from 'views/dashboard/Default/SubFooter';
import GradientCircle from 'views/dashboard/Default/GradientCircle';
import { useState, useEffect } from 'react';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    ...theme.typography.mainContent
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const theme = useTheme();
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 10) {
                // Change '10' to the scroll threshold you prefer
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Add event listener
        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    top: 0,
                    bottom: 'auto',
                    backgroundColor: isScrolled ? theme.palette.background.default : 'transparent',
                    boxShadow: 0,
                    height: 120,
                    zIndex: 999
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>
            <Main theme={theme}>
                <Outlet />
            </Main>
            <Grid item>
                <SubFooter />
            </Grid>
        </>
    );
};

export default MainLayout;
