import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, Grid, IconButton, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import alxlogo from '../../assets/alxlogo.svg';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import TelegramIcon from '@mui/icons-material/Telegram';

export default function MenuDrawer({ isOpen, onClose }) {
    const theme = useTheme();
    const list = () => (
        <List sx={{ width: '100vw', bgcolor: 'background.paper', height: '100vh' }} component="nav">
            <Grid container xs={12} sm={12} justifyContent="space-between" mb={2}>
                <img src={alxlogo} alt="alphaxlogo" width={35} height={35} style={{ marginLeft: 20, marginTop: 10 }} />
                <IconButton onClick={onClose} sx={{ width: 35, height: 35, mt: 0.8, mr: 2 }}>
                    <CloseIcon sx={{ color: grey[50] }} />
                </IconButton>
            </Grid>
            <Link to="/lease" style={{ textDecoration: 'none' }}>
                <ListItemButton
                    onClick={onClose}
                    sx={{ m: 2, pt: 3, borderColor: grey[600], '&:hover': { bgcolor: 'unset', color: 'unset' } }}
                >
                    <ListItemText sx={{ ml: -2 }}>
                        <Typography sx={{ color: grey[200], fontWeight: 505 }}>Lease</Typography>
                    </ListItemText>
                </ListItemButton>
            </Link>
            <Link to="/lendborrow" style={{ textDecoration: 'none' }}>
                <ListItemButton
                    onClick={onClose}
                    sx={{
                        borderTop: 1,
                        m: 2,
                        pt: 3,
                        borderColor: grey[600],
                        color: grey[300],
                        '&:hover': { bgcolor: 'unset', color: grey[300] }
                    }}
                >
                    <ListItemText sx={{ ml: -2 }}>
                        <Typography sx={{ color: grey[200], fontWeight: 505 }}>Lend and borrow</Typography>
                    </ListItemText>
                </ListItemButton>
            </Link>
            <Link to="/auctions" style={{ textDecoration: 'none' }}>
                <ListItemButton
                    onClick={onClose}
                    sx={{
                        borderTop: 1,
                        m: 2,
                        pt: 3,
                        borderColor: grey[600],
                        color: grey[300],
                        '&:hover': { bgcolor: 'unset', color: grey[300] }
                    }}
                >
                    <ListItemText sx={{ ml: -2 }}>
                        <Typography sx={{ color: grey[200], fontWeight: 505 }}>Auctions</Typography>
                    </ListItemText>
                </ListItemButton>
            </Link>
            <Link to="/whitepaper" style={{ textDecoration: 'none' }}>
                <ListItemButton
                    onClick={onClose}
                    sx={{
                        borderTop: 1,
                        m: 2,
                        pt: 3,
                        borderColor: grey[600],
                        color: grey[300],
                        '&:hover': { bgcolor: 'unset', color: grey[300] }
                    }}
                >
                    <ListItemText sx={{ ml: -2 }}>
                        <Typography sx={{ color: grey[200], fontWeight: 505 }}>Documentation</Typography>
                    </ListItemText>
                </ListItemButton>
            </Link>
            <Grid container p={2} display="flex" flexDirection="column">
                <Button
                    fullWidth
                    href="https://alphadao.finance"
                    size="small"
                    endIcon={<PlayArrowRoundedIcon style={{ fontSize: 30 }} />}
                    sx={{
                        background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                        borderRadius: 50,
                        textTransform: 'none',
                        fontWeight: 600,
                        fontSize: 20,
                        height: 50,
                        color: grey[100]
                    }}
                >
                    Launch dApp
                </Button>
                <Button
                    fullWidth
                    href="https://t.me/alphadao_token"
                    size="small"
                    endIcon={<TelegramIcon style={{ fontSize: 30 }} />}
                    sx={{
                        background: 'transparent',
                        borderRadius: 50,
                        textTransform: 'none',
                        fontWeight: 600,
                        mt: 2,
                        fontSize: 20,
                        border: 1,
                        height: 50,
                        color: grey[100]
                    }}
                >
                    Join the community
                </Button>
            </Grid>
        </List>
    );

    return (
        <div>
            <Drawer anchor="right" open={isOpen} onClose={onClose}>
                <Box
                    sx={{
                        borderRadius: 10,
                        bgcolor: theme.palette.background.default,
                        right: 0
                    }}
                >
                    {list()}
                </Box>
            </Drawer>
        </div>
    );
}
