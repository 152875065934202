import { Button, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { Link } from 'react-router-dom';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const HeaderMenu = () => {
    const theme = useTheme();
    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    height: 70,
                    p: 1,
                    bgcolor: 'transparent'
                }}
            >
                <Button component={Link} to="/lease" sx={{ bgcolor: 'transparent', color: grey[50], textTransform: 'none', fontSize: 18 }}>
                    Lease
                </Button>
                <Button
                    component={Link}
                    to="/lendborrow"
                    sx={{ bgcolor: 'transparent', color: grey[50], textTransform: 'none', fontSize: 18 }}
                >
                    Lend and borrow
                </Button>
                <Button
                    component={Link}
                    to="/auctions"
                    sx={{ bgcolor: 'transparent', color: grey[50], textTransform: 'none', fontSize: 18 }}
                >
                    Auctions
                </Button>
                <Button
                    component={Link}
                    to="/whitepaper"
                    sx={{ bgcolor: 'transparent', color: grey[50], textTransform: 'none', fontSize: 18 }}
                >
                    Docs
                </Button>
            </Grid>
        </>
    );
};

export default HeaderMenu;
