// material-ui
import { useTheme } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import ethlogo from '../../../assets/ethdisabled.svg';
import avalanchelogo from '../../../assets/avaxdisabled.svg';
import polygonlogo from '../../../assets/polygondisabled.svg';
import CircleIcon from '@mui/icons-material/Circle';
import bsclogo from '../../../assets/BSCLogo.png';
import HeadTypography from './HeadTypography';
import GradientHeadTypography from './GradientHeadTypography';

const platformData = [
    { logo: bsclogo, name: 'BSC', active: true },
    { logo: ethlogo, name: 'Ethereum', active: false },
    { logo: avalanchelogo, name: 'Avalanche', active: false },
    { logo: polygonlogo, name: 'Polygon', active: false }
];

const CommonTypography = ({ value, label }) => (
    <Typography
        sx={{
            color: grey[50],
            fontSize: 25,
            fontWeight: 600,
            textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' }
        }}
    >
        {value}
        <Typography sx={{ color: grey[400], textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' } }}>{label}</Typography>
    </Typography>
);

const AvailableNetworks = () => {
    const theme = useTheme();

    return (
        <>
            <Grid
                container
                lg={9}
                md={11.5}
                sm={11}
                xs={11}
                sx={{
                    display: 'flex',
                    pt: { lg: 5, md: 5, sm: 5, xs: 0 },
                    justifyContent: 'space-evenly',
                    borderTop: { lg: 1, md: 1, sm: 1, xs: 0 },
                    borderRadius: 20,
                    borderColor: { lg: '#f13c58', md: '#f13c58', sm: '#f13c58' }
                }}
            >
                {[
                    { value: '0', label: 'Assets supplied' },
                    { value: '0', label: 'Active offers' },
                    { value: '$0.00', label: 'Total volume' },
                    { value: '0', label: 'Total trades' }
                ].map((item, index) => (
                    <Grid key={index} item sm={5} xs={5} md={2} lg={2} sx={{ mt: { lg: 0, md: 0, sm: 5, xs: 5 } }}>
                        <CommonTypography {...item} />
                    </Grid>
                ))}
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 10, p: 2 }}>
                <Grid container width="100%" justifyContent="center" display="flex">
                    <HeadTypography text="Available on " />
                    <GradientHeadTypography text="multiple networks" />
                </Grid>
                {platformData.map((platform, index) => (
                    <Grid
                        key={index}
                        container
                        xs={3.5}
                        lg={2}
                        md={2}
                        sm={2}
                        sx={{
                            display: 'flex',
                            height: { lg: 100, md: 100, sm: 80, xs: 50 },
                            width: { lg: 100, md: 100, sm: 80, xs: 50 },
                            justifyContent: 'center',
                            my: 5
                        }}
                    >
                        <Grid
                            item
                            sx={{
                                height: { lg: 100, md: 100, sm: 80, xs: 50 },
                                width: { lg: 100, md: 100, sm: 80, xs: 50 }
                            }}
                        >
                            <img src={platform.logo} alt={platform.name} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item width="100%" display="flex" justifyContent="center" alignItems="center" pt={2}>
                            <Typography textAlign="center" variant="m3" sx={{ color: grey[200] }}>
                                {platform.name}
                            </Typography>
                            <CircleIcon sx={{ color: platform.active ? green[500] : grey[700], fontSize: 15, ml: 0.8 }} />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default AvailableNetworks;
