import { Grid, Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import ArticleIcon from '@mui/icons-material/Article';
import lendborrowbanner from '../../assets/questionmarkbannerauctions.svg';
import mainlendborrowbanner from '../../assets/auctionbanner.svg';
import GradientCircle from 'views/dashboard/Default/GradientCircle';
import ExploreIcon from '@mui/icons-material/Explore';
import FeedIcon from '@mui/icons-material/Feed';

const StatCard = ({ value, label }) => (
    <Grid
        container
        xs={5.5}
        lg={3.15}
        md={3.15}
        sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: 2,
            bgcolor: 'rgba(40, 36, 43, 0.5)',
            width: 'auto',
            boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
            alignItems: 'center',
            m: { xs: 0.5, sm: 0.5, md: 1, lg: 1 },
            p: 2
        }}
    >
        <Typography color={grey[50]} fontSize={22}>
            {value}
        </Typography>
        <Typography textAlign="center" color={grey[400]}>
            {label}
        </Typography>
    </Grid>
);

const Auctions = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container sx={{ justifyContent: 'left', p: 2, display: 'flex', mb: 10, mt: 20 }}>
                {/* Introduction */}
                <Grid container sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mt: 5 }}>
                    <Grid container xs={12} sm={12} md={6} lg={6}>
                        <Typography
                            textAlign="left"
                            sx={{
                                backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                                fontSize: { lg: 60, md: 50, sm: 40, xs: 40 },
                                backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                                backgroundSize: '100%',
                                backgroundRepeat: 'repeat',
                                fontWeight: 505,
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            AlphaDAO
                        </Typography>
                        <Typography
                            sx={{
                                color: grey[50],
                                fontSize: { lg: 60, md: 50, sm: 40, xs: 40 },
                                fontWeight: 505,
                                ml: 1
                            }}
                        >
                            - Auctions
                        </Typography>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' }, justifyContent: 'center' }}
                        >
                            <Grid item xs={12} sm={8} mt={3}>
                                <img src={mainlendborrowbanner} alt="banner" style={{ width: '100%' }} />
                            </Grid>
                        </Grid>
                        <Typography fontSize={18} sx={{ color: grey[400], mt: 4 }}>
                            The ALX token distribution is designed to be unique and innovative compared to traditional token launches. 20%
                            of the total ALX token supply is distributed through the AlphaDAO Auctions. The distribution period lasts for
                            100 days, with 200,000 ALX tokens distributed each day. Over the entire period, a total of 20,000,000 ALX tokens
                            will be distributed. This approach ensures a fair and gradual distribution of tokens, aligning with our
                            commitment to transparency and community engagement.
                        </Typography>
                        <Button
                            href="https://app.alphax.social/dashboard"
                            startIcon={<ExploreIcon sx={{ color: grey[50] }} />}
                            sx={{
                                background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                                borderRadius: 50,
                                fontSize: 18,
                                textTransform: 'none',
                                width: { xs: '100%', sm: '100%', md: '40%', lg: '30%' },
                                mx: 0.5,
                                mt: 3,
                                color: grey[50]
                            }}
                        >
                            Explore
                        </Button>
                        <Button
                            href="https://app.alphax.social/dashboard"
                            startIcon={<FeedIcon sx={{ color: grey[50] }} />}
                            sx={{
                                backgroundColor: 'transparent',
                                borderRadius: 50,
                                border: 1,
                                borderColor: grey[500],
                                fontSize: 18,
                                textTransform: 'none',
                                width: { xs: '100%', sm: '100%', md: '40%', lg: '30%' },
                                mx: 0.5,
                                ml: { lg: 3, md: 3, sm: 0, xs: 0 },
                                mt: 3,
                                color: grey[50],
                                ':hover': { backgroundColor: 'transparent' }
                            }}
                        >
                            Learn more
                        </Button>
                    </Grid>
                    <Grid item md={3} lg={3} sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                        <img src={mainlendborrowbanner} alt="banner" style={{ width: '100%' }} />
                    </Grid>
                </Grid>
                {/* Stats boxes */}
                <Grid container lg={12} xs={12} sm={12} md={12} my={5} display="flex" justifyContent="center">
                    <StatCard value="0.00" label="Total USDT contributed" />
                    <StatCard value="0" label="Total participants" />
                    <StatCard value="0.00" label="ALX burnt" />
                    <StatCard value="0" label="Total HPDs" />
                </Grid>
                {/* How it works? */}
                <Grid container lg={6} md={6} mt={5}>
                    <Grid item display="flex" justifyContent="center" sx={{ pl: { xs: 0, sm: 0, md: 0, lg: 10 } }}>
                        <img
                            src={lendborrowbanner}
                            alt="banner"
                            style={{
                                objectFit: 'cover',
                                width: '90%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container lg={6} md={6} alignItems="center" mt={5}>
                    <Grid container lg={10} xs={12} sm={12} md={11}>
                        <Typography
                            sx={{
                                color: grey[50],
                                fontSize: { lg: 60, md: 50, sm: 40, xs: 30 },
                                pl: { xs: 0, sm: 0, md: 7.5, lg: 10 },
                                pr: 1,
                                fontWeight: 505
                            }}
                        >
                            How it
                        </Typography>
                        <Typography
                            textAlign="left"
                            sx={{
                                backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                                fontSize: { lg: 60, md: 50, sm: 40, xs: 30 },
                                backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                                backgroundSize: '100%',
                                backgroundRepeat: 'repeat',
                                fontWeight: 505,
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            works?
                        </Typography>
                        <Grid
                            container
                            sx={{
                                height: '50vh',
                                width: '50vw',
                                zIndex: -1,
                                opacity: 0.6,
                                position: 'absolute',
                                top: 0,
                                mt: { lg: 60, md: 65, sm: 180, xs: 180 }
                            }}
                        >
                            <GradientCircle color="#e7645a" size={1000} />
                        </Grid>
                        <Typography fontSize={18} sx={{ color: grey[400], mt: 2, pl: { xs: 0, sm: 0, md: 7.5, lg: 10 }, pr: 2 }}>
                            Users can reserve ALX tokens by contributing USDT to the daily pool. The price of ALX is determined at the end
                            of each day based on the ratio of contributed USDT to the 200,000 ALX tokens distributed that day. The collected
                            USDT is used for random paybacks, called `Happy Pay Day` and for burning ALX tokens.
                        </Typography>
                        <Typography fontSize={18} sx={{ color: grey[400], mt: 2, pl: { xs: 0, sm: 0, md: 7.5, lg: 10 }, pr: 2 }}>
                            Each address can participate once per day, but can participate every day.
                        </Typography>
                        <Button
                            startIcon={<ArticleIcon sx={{ color: grey[50] }} />}
                            href="https://app.alphax.social/dashboard"
                            sx={{
                                background: `linear-gradient(to right, #9a48dc, #9a48dc, #f13c58)`,
                                borderRadius: 50,
                                fontSize: 18,
                                textTransform: 'none',
                                width: { xs: '100%', sm: '100%', md: '50%', lg: '40%' },
                                ml: { xs: 0, sm: 0, md: 7.5, lg: 10 },
                                mt: 4,
                                color: grey[50],
                                boxShadow: '2px 0px 60px #ce447a',
                                ':hover': { borderColor: grey[50], backgroundColor: grey[50] }
                            }}
                        >
                            Documentation
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Auctions;
