import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FAQs from './FAQs';
// project imports
// ==============================|| TYPOGRAPHY ||============================== //

const FAQsMain = () => {
    useTheme();
    return (
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
            <FAQs />
        </Grid>
    );
};

export default FAQsMain;
