import { Grid, Typography, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { FaXTwitter, FaTelegram, FaDiscord } from 'react-icons/fa6';
import FAQAccordionFull from 'views/dashboard/Default/FAQAccordionFull';

const FAQs = () => {
    const theme = useTheme();
    return (
        <>
            <Grid
                item
                width="100%"
                sx={{
                    mt: { xs: 8, sm: 8, md: 9, lg: 9 },
                    display: 'flex',
                    justifyContent: 'center',
                    background: 'transparent',
                    py: 5,
                    flexDirection: 'column'
                }}
            >
                <Grid container display="flex" justifyContent="center" xs={12} p={2} mt={5}>
                    <Typography
                        sx={{
                            color: grey[50],
                            fontSize: { lg: 50, md: 40, sm: 30, xs: 30 },
                            fontWeight: 505,
                            textAlign: 'center'
                        }}
                    >
                        How can
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{
                            backgroundColor: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                            fontSize: { lg: 50, md: 40, sm: 30, xs: 30 },
                            letterSpacing: '1px',
                            backgroundImage: `linear-gradient(45deg, #9a48dc, #f9774b, #f13c58)`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'repeat',
                            fontWeight: 505,
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            ml: 1
                        }}
                    >
                        we help
                    </Typography>
                    <Typography
                        sx={{
                            color: grey[50],
                            fontSize: { lg: 50, md: 40, sm: 30, xs: 30 },
                            fontWeight: 505,
                            textAlign: 'center',
                            ml: 1
                        }}
                    >
                        you?
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="center" width="100%">
                    <Grid
                        container
                        display="flex"
                        justifyContent="space-evenly"
                        sx={{ width: { lg: '40%', md: '50%', sm: '90%', xs: '100%' } }}
                    >
                        <Grid item display="flex" justifyContent="center" flexDirection="column">
                            <IconButton href="https://x.com/alphadaotoken">
                                <FaXTwitter style={{ color: grey[50], fontSize: 35 }} />
                            </IconButton>
                            <Typography textAlign="center" color={grey[50]} fontWeight={405} fontSize={16}>
                                X
                            </Typography>
                        </Grid>
                        <Grid item display="flex" justifyContent="center" flexDirection="column">
                            <IconButton href="https://x.com/alphadaotoken">
                                <FaDiscord style={{ color: grey[50], fontSize: 35 }} />
                            </IconButton>
                            <Typography textAlign="center" color={grey[50]} fontWeight={405} fontSize={16}>
                                Dicosrd
                            </Typography>
                        </Grid>
                        <Grid item display="flex" justifyContent="center" flexDirection="column">
                            <IconButton href="https://t.me/alphadao_token">
                                <FaTelegram style={{ color: grey[50], fontSize: 35 }} />
                            </IconButton>
                            <Typography textAlign="center" color={grey[50]} fontWeight={405} fontSize={16}>
                                Telegram
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ justifyContent: 'left', p: { xs: 0, sm: 2, md: 2, lg: 2 }, display: 'flex' }}>
                <FAQAccordionFull />
            </Grid>
        </>
    );
};

export default FAQs;
