// material-ui
import { useTheme } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { cardcolor } from 'themes/constants';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const FAQAccordionFull = () => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <Grid container display="flex" justifyContent="center" alignItems="center" mb={7} px={2}>
                <Grid item lg={8} md={10} mt={5}>
                    <Grid item sx={{ background: cardcolor, boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)', borderRadius: 2 }}>
                        <Accordion
                            expanded={expanded === 'panel1'}
                            onChange={handleChange('panel1')}
                            disableGutters
                            sx={{ bgcolor: 'transparent', boxShadow: 0, borderRadius: 2 }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                                <Typography fontWeight={505} py={2} letterSpacing="1px" color={grey[50]}>
                                    What is AlphaDAO?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color={grey[400]} pb={5} letterSpacing="1px">
                                    Launched in 2024, AlphaDAO is a distinguished DAO blockchain-enabled platform meticulously curated for
                                    the seamless integration of decentralized finance. The platform pioneers the world`s first lending and
                                    borrowing framework, allowing users to engage in peer-to-peer transactions with any asset. Elevating the
                                    user experience, AlphaDAO operates under the governance of its dedicated community.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            background: cardcolor,
                            boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
                            borderRadius: 2,
                            mt: 2
                        }}
                    >
                        <Accordion disableGutters sx={{ bgcolor: 'transparent', boxShadow: 0, borderRadius: 2 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                                <Typography fontWeight={505} color={grey[50]} py={2} letterSpacing="1px">
                                    How does AlphaDAO work?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color={grey[400]} pb={5} letterSpacing="1px">
                                    AlphaDAO is decentralized autonomous organization which has been designed and built in a way where the
                                    community expess their rights to vote and decide the future improvements and developments of the
                                    platform. There is no person or entity that governs or dictates the rules of the projects.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            background: cardcolor,
                            boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
                            borderRadius: 2,
                            mt: 2
                        }}
                    >
                        <Accordion disableGutters sx={{ bgcolor: 'transparent', boxShadow: 0, borderRadius: 2 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                                <Typography fontWeight={505} color={grey[50]} py={2} letterSpacing="1px">
                                    What are ALX tokens?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color={grey[400]} pb={5} letterSpacing="1px">
                                    ALX is an ERC20 token and it has ALX symbol with 18 decimals. It is the main utility token of the
                                    project and secures multiple benefits for its holders. ALX tokens might be held in any web3 wallet.
                                    Holding or certain amount of the ALX tokens secures discount on the Lease, Lend & Borrow platforms.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            background: cardcolor,
                            boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
                            borderRadius: 2,
                            mt: 2
                        }}
                    >
                        <Accordion disableGutters sx={{ bgcolor: 'transparent', boxShadow: 0, borderRadius: 2 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                                <Typography fontWeight={505} color={grey[50]} py={2} letterSpacing="1px">
                                    What digital assets does the AlphaDAO `Lease, Lend and Borrow` platforms support?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color={grey[400]} pb={5} letterSpacing="1px">
                                    AlphaDAO Lend and Borrow platform allows everyone to supply/borrow any ERC20 asset. It is the very first
                                    peer-to-peer lend and borrow platform where users set up their own offers with their own conditions
                                    unlocking infinite flexibility.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            background: cardcolor,
                            boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
                            borderRadius: 2,
                            mt: 2
                        }}
                    >
                        <Accordion disableGutters sx={{ bgcolor: 'transparent', boxShadow: 0, borderRadius: 2 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                                <Typography fontWeight={505} color={grey[50]} py={2} letterSpacing="1px">
                                    Is there a minimum or maximum of how many ALX tokens can be hold?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color={grey[400]} pb={5} letterSpacing="1px">
                                    There are no restrictions in terms of how many tokens an address may hold.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            background: cardcolor,
                            boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
                            borderRadius: 2,
                            mt: 2
                        }}
                    >
                        <Accordion disableGutters sx={{ bgcolor: 'transparent', boxShadow: 0, borderRadius: 2 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: grey[50] }} />}>
                                <Typography fontWeight={505} color={grey[50]} py={2} letterSpacing="1px">
                                    How are the platform fees distributed?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color={grey[400]} pb={5} letterSpacing="1px">
                                    AlphaDAO Lease, Lend and Borrow platforms allow everyone to supply/borrow any digital asset. It is the
                                    very first peer-to-peer lease, lend and borrow platforms where users set up their own offers with their
                                    own conditions unlocking infinite flexibility.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default FAQAccordionFull;
