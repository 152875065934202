import { Grid, Typography } from '@mui/material';
import Marquee from 'react-fast-marquee';
import mmlogo from '../../../assets/MMMovingLogo.svg';
import twlogo from '../../../assets/TWMovingLogo.png';
import cmclogo from '../../../assets/CMCMovingLogo.png';
import cglogo from '../../../assets/CGMovingLogo.png';
import ledgerlogo from '../../../assets/ledgernanologo.svg';
import rabbywalletlogo from '../../../assets/rabbywalletlogo.svg';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import GradientHeadTypography from './GradientHeadTypography';
import HeadTypography from './HeadTypography';

const MarqueeItem = ({ logo }) => (
    <Grid item p={4} mx={3} bgcolor={grey[200]} borderRadius={2} sx={{ height: 100 }}>
        <img src={logo} alt="banner" style={{ height: '100%', objectFit: 'cover' }} />
    </Grid>
);

const Partners = () => {
    const theme = useTheme();
    return (
        <>
            <Grid item lg={5} sx={{ display: 'flex', justifyContent: 'center', mb: 7, flexDirection: 'column' }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                    <GradientHeadTypography text="Partnership" />
                    <HeadTypography text="excellence" />
                </Grid>
                <Typography textAlign="center" fontSize={18} sx={{ color: grey[400], px: 2 }}>
                    Within the AlphaDAO ecosystem, we are dedicated to fostering impactful collaborations with various entities, ranging
                    from centralized exchanges to cutting-edge web3 projects. Our commitment extends beyond mere partnerships. We actively
                    pursue and welcome new projects onto our platform.
                </Typography>
            </Grid>
            <Grid item width="100%">
                <Marquee autoFill pauseOnHover gradient speed={150} gradientColor={theme.palette.background.paper}>
                    {[mmlogo, twlogo, cmclogo, cglogo, ledgerlogo, rabbywalletlogo].map((logo, index) => (
                        <MarqueeItem key={index} logo={logo} />
                    ))}
                </Marquee>
            </Grid>
        </>
    );
};

export default Partners;
