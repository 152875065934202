// material-ui
import { useTheme } from '@mui/material/styles';
import alphaxtransparent from 'assets/AlphaXWhiteLogo.svg';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    useTheme();

    return <img height={40} src={alphaxtransparent} alt="alxlogo" />;
};

export default Logo;
