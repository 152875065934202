import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Lease from './Lease';
// project imports
// ==============================|| TYPOGRAPHY ||============================== //

const LeaseMain = () => {
    useTheme();
    return (
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
            <Lease />
        </Grid>
    );
};

export default LeaseMain;
