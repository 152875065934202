// routes/index.js

import { useRoutes } from 'react-router-dom';
import ScrollToTop from '../ui-component/ScrollToTop'; // Import ScrollToTop

// routes
import MainLayout from 'layout/MainLayout';
import Dashboard from 'views/dashboard/Default';
import Whitepaper from 'views/utilities/Whitepaper';
import SamplePage from 'views/sample-page';
import ProductsMain from 'views/utilities/ProductsMain';
import PressRoomMain from 'views/utilities/PressRoomMain';
import FAQsMain from 'views/utilities/FAQsMain';
import LeaseMain from 'views/utilities/LeaseMain';
import AuctionsMain from 'views/utilities/AuctionsMain';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const routes = useRoutes([
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Dashboard />
                }
            ]
        },
        {
            path: '/whitepaper',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Whitepaper />
                }
            ]
        },
        {
            path: '/lendborrow',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <ProductsMain />
                }
            ]
        },
        {
            path: '/lease',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <LeaseMain />
                }
            ]
        },
        {
            path: '/auctions',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <AuctionsMain />
                }
            ]
        },
        {
            path: '/faqs',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <FAQsMain />
                }
            ]
        },
        {
            path: '/pressroom',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <PressRoomMain />
                }
            ]
        },
        {
            path: '/sample-page',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <SamplePage />
                }
            ]
        }
    ]);

    return (
        <>
            <ScrollToTop /> {/* Add ScrollToTop here */}
            {routes}
        </>
    );
}
