import Typography from '@mui/material/Typography';

// Custom Typography Component
const GradientHeadTypography = ({ text, centered, ...props }) => (
    <Typography
        textAlign={centered ? 'center' : 'left'}
        sx={{
            fontSize: { lg: 60, md: 50, sm: 40, xs: 30 },
            fontWeight: 605,
            zIndex: 995,
            px: { xs: 1, lg: 1 },
            backgroundImage: `linear-gradient(45deg, #ce447a, #f9774b, #f9774b)`,
            backgroundSize: '100%',
            backgroundRepeat: 'repeat',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        }}
    >
        {text}
    </Typography>
);

export default GradientHeadTypography;
