import { useState } from 'react';
import { Grid, Typography, Step, StepLabel, Box, Stepper, StepConnector, stepConnectorClasses, IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { gradintBackgroundDark } from 'themes/constants';

const steps = [
    {
        label: 'The idea is born',
        description: `The initial concept has been successfully developed, marking the foundational phase of the AlphaDAO project.`
    },
    {
        label: 'Website is created',
        description: 'The official AlphaDAO website has been launched, providing a platform for information and community engagement.'
    },
    {
        label: 'Smart contracts are developed',
        description:
            'Key smart contracts have been meticulously developed to ensure secure and efficient operations within the AlphaDAO ecosystem.'
    },
    {
        label: 'Multiple testnets',
        description: `Comprehensive testing on multiple testnets is currently underway to validate the functionality and security of the smart contracts.`
    },
    {
        label: 'Marketing phase',
        description: `Strategic marketing efforts will be initiated to raise awareness and build a strong community around AlphaDAO.`
    },
    {
        label: 'AlphaDAO launch on BSC',
        description: `The AlphaDAO project will be officially launched on the Binance Smart Chain (BSC), making it accessible to a wider audience.`
    },
    {
        label: 'ALX Predictions & staking',
        description: `Futures-like predictions on the ALX price generating USDT revenue which gets distributed to stakers.`
    },
    {
        label: 'CEX listing',
        description: `AlphaDAO will be listed on a centralized exchange, increasing accessibility and liquidity for users.`
    },
    {
        label: 'Launch on other EVMs',
        description: `The project will expand to other Ethereum Virtual Machine (EVM) compatible networks, enhancing its reach and interoperability.`
    },
    {
        label: 'Launch of AlphaDAO Social',
        description: `A social application will be launched to facilitate community interaction and engagement within the AlphaDAO ecosystem.`
    },
    {
        label: 'Launch AlphaDAO DEX',
        description: `The AlphaDAO decentralized exchange (DEX) will be introduced, providing users with a secure and efficient platform for trading digital assets.`
    }
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#f9774b'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#f9774b'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#f9774b'
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#f9774b',
        zIndex: 1,
        fontSize: 18
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

export default function RoadmapStepper() {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const handleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    return (
        <>
            <Box
                sx={{
                    maxWidth: 450,
                    maxHeight: isExpanded ? 'auto' : 350,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    boxShadow: 'inset 0 0 80px rgba(255, 255, 255, 0.1)',
                    bgcolor: `rgba(40, 36, 43, 0.5)`,
                    backdropFilter: 'blur(4px)',
                    p: 3,
                    borderRadius: 3
                }}
            >
                <Stepper activeStep={3} orientation="vertical" connector={<QontoConnector />}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Grid container display="flex">
                                    <Typography sx={{ color: grey[200], fontWeight: 700, fontSize: 16 }}>{step.label}</Typography>
                                    <Typography sx={{ color: grey[500], fontWeight: 600, fontSize: 14 }}>{step.description}</Typography>
                                </Grid>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Grid container display="flex" justifyContent="center" mt={-3}>
                <IconButton size="small" onClick={handleExpand}>
                    <ExpandCircleDownIcon sx={{ color: '#f9774b', fontSize: 35, transform: isExpanded ? 'rotate(180deg)' : null }} />
                </IconButton>
            </Grid>
        </>
    );
}
